import {Injectable} from '@angular/core';
import {LocalStorageService} from "./local-storage.service";
import {Config} from "../config/config";
import {Params} from "@angular/router";
import {TYPE} from "../types/types";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private customer: TYPE.Customer | null = null;
  private customerKey: string | null = null;

  constructor(
    private localStorageService: LocalStorageService
  ) {
    this.setCustomerKey(this.getStoredCustomerKey());
  }

  public setCustomerKey(key: string | null): void {
    this.customerKey = key;
  }

  public getCustomer(): TYPE.Customer | null {
    return this.customer;
  }

  public getCustomerKey(): string | null {
    return this.customerKey;
  }

  public setCustomer(customer: TYPE.Customer): void {
    this.localStorageService.setLocalStorage('customerKey', customer.extension);
    this.customer = customer;
  }

  public resetCustomer(): void {
    this.customer = null;
    this.localStorageService.removeItem('customerKey');
  }

  public getStoredCustomerKey(params?: Params): string | null {
    // customer cases ordered by priority: customerKey in localstorage, customerKey in url, customerKey in hostname, customerKey in config
    if (this.localStorageService.getLocalStorage('customerKey')) {
      return this.localStorageService.getLocalStorage('customerKey');
    }

    if (params && params.hasOwnProperty('customerKey') && params['customerKey'].length && params['customerKey'].length > 2) {
      return params['customerKey'];
    }

    if (this.getCustomerKeyFromHostname()) {
      return this.getCustomerKeyFromHostname();
    }

    if (this.getCustomerKeyFromConfig()) {
      return this.getCustomerKeyFromConfig();
    }

    return null;
  }

  private getCustomerKeyFromHostname(): string | null {
    const currentHostname = window.location.hostname;

    if (currentHostname === 'app.schnellecke.com') {
      return 'schnellecke';
    }
    if (currentHostname === 'intranet.wz.de') {
      return 'westdeutsche';
    }
    if (currentHostname === 'app.miabus.de') {
      return 'miabus';
    }
    if (currentHostname === 'team-app.maprom.net') {
      return 'maprom';
    }

    let url = window.location.href;
    url = url.replace('https://', '');
    url = url.replace('http://', '');

    const urlArr = url.split('.');
    if (urlArr[0] !== 'www' &&
      urlArr[0] !== 'www2' &&
      urlArr[0] !== 'desktop' &&
      urlArr[0] !== 'stage' &&
      urlArr[0] !== 'web' &&
      (
        urlArr[1] === 'valido-max' ||
        urlArr[1] === 'max-toolbox'
      ) &&
      urlArr[2].substring(0, 3) === 'com'
    ) {
      return urlArr[0];
    }

    return null;
  }

  private getCustomerKeyFromConfig(): string | null {
    if (Config.CUSTOMER_KEY_STRING) {
      return Config.CUSTOMER_KEY_STRING;
    }
    return null
  }
}
