import {CanActivateFn} from '@angular/router';
import {inject} from "@angular/core";
import {UserService} from "../services/user.service";
import {RouterService} from "../services/router.service";

export const userGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  if (userService.getUser()) {
    return true;
  } else {
    const routerService = inject(RouterService);
    routerService.navigateTo('/auth/login');
  }
  return false;
};
