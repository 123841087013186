import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {ApiService} from "./api.service";
import {TYPE} from "../types/types";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private language: string = 'de';
  private languageDefault = (navigator.language && navigator.language.substring(0, 2) !== 'de') ? 'en' : 'de';
  private languages: TYPE.Languages = {};
  private subscription: Subscription | null = null;
  private loadingIndicatorSubject = new BehaviorSubject<number>(0);
  public loadingIndicator$ = this.loadingIndicatorSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) {
    console.log('LanguageService.constructor');
  }

  public setLanguage(language: string): void {
    if (language) {
      this.language = language.toLowerCase();
      this.loadingIndicatorSubject.next(1);

      // it has to be a timeout because the pipes will not update otherwise
      setTimeout(() => {
        this.loadingIndicatorSubject.next(0);
      });
    }
  }

  public setLanguageDefault(): void {
    this.language = this.languageDefault;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getTranslationByKey(key: string): string {
    try {
      if (this.languages[this.language][key]) {
        return this.languages[this.language][key];
      }
    } catch (e) {
    }
    return key;
  }

  private setLanguages(languages: TYPE.Languages): void {
    this.languages = languages;
  }

  public setCustomerLanguages(languages: TYPE.Languages): void {
    for (let languagesKey in languages) {
      for (let languageValue in languages[languagesKey]) {
        if (!this.languages[languagesKey.toLowerCase()]) {
          this.languages[languagesKey.toLowerCase()] = {};
        }
        this.languages[languagesKey.toLowerCase()][languageValue] = languages[languagesKey][languageValue];
      }
    }
  }

  public hasLanguageKey(key: string): boolean {
    return !!this.languages[this.language][key];
  }

  public init(): Promise<boolean> {
    if (this.subscription) {
      return new Promise<boolean>(
        (resolve) => {
          resolve(true);
        }
      )
    } else {
      this.setLanguageDefault();
      return new Promise<boolean>(
        (resolve) => {
          this.subscription = this.apiService.getLanguages().subscribe({
            next: (result) => {
              this.setLanguages(result);
              resolve(true);
            }, error: (error) => {
              resolve(false);
            }
          });
        }
      )
    }
  }
}
