import {CanActivateFn} from '@angular/router';
import {inject} from "@angular/core";
import {CustomerService} from "../services/customer.service";
import {RouterService} from "../services/router.service";

export const customerGuard: CanActivateFn = (route, state) => {
  const customerService = inject(CustomerService);
  if (customerService.getCustomer()) {
    return true;
  } else {
    const routerService = inject(RouterService);
    routerService.navigateTo('/auth/customer');
  }
  return false;
};
