import {Injectable} from '@angular/core';
import {TYPE} from "../types/types";
import {LocalStorageService} from "./local-storage.service";
import {SocketService} from "./socket.service";
import {LanguageService} from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user: TYPE.User | null = null;
  private token: string | null = null;

  constructor(
    private localStorageService: LocalStorageService,
    private socketService: SocketService,
    private languageService: LanguageService
  ) {
  }

  public setToken(token: string | null): void {
    this.token = token;
  }

  public getToken(): string | null {
    return this.token;
  }

  public setUser(user: TYPE.User): void {
    if (user.token) this.localStorageService.setLocalStorage('userToken', user.token);
    this.user = user;
    this.socketService.connect(user.id);
  }

  public getUser(): TYPE.User | null {
    return this.user;
  }

  public resetUser(): void {
    this.user = null;
    this.localStorageService.removeItem('userToken');
    this.languageService.setLanguageDefault();
    this.socketService.disconnect();
  }

  public getStoredUserToken(): string | null {
    return this.localStorageService.getLocalStorage('userToken');
  }
}
